
import Vue from "vue";
import userService from "@/services/userService";

export default Vue.extend({
  data() {
    return {
      showPassword: false,
      password: "",
      email: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      loggingIn: false,
      resetting: false,
      valid: false,
    };
  },
  methods: {
    async forgetPassword() {
      try {
        if (this.email) {
          this.resetting = true;
          await userService.resetPasswordUser(this.email);
          this.$notification.showSuccess(`The reset password link has been sent to '${this.email}'`);
        } else {
          this.$notification.showError("Please enter your email address.");
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.resetting = false;
      }
    },
    async login() {
      try {
        if (!this.valid) {
          return;
        }
        this.loggingIn = true;
        const result = await userService.singInWithCredential(this.email, this.password);
        // eslint-disable-next-line no-console
        console.log("storeUserDataAndCompany", result.user);
        await userService.storeUserDataAndCompany(result.user);
        this.$notification.showSuccess("Login was successful.");
        const returnUrl = this.$route.query["returnUrl"];
        if (returnUrl) {
          await this.$router.push(returnUrl.toString());
        } else {
          await this.$router.push("forms");
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loggingIn = false;
      }
    },
  },
});
